@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  background: linear-gradient(108deg, #643e8b 35%, #189e9d 100%);
  font-family: "Poppins", serif !important;
  height: 100vh;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  position: relative;
}

.title {
  color: #fff;
  font-size: 3.5rem !important;
  font-weight: 500 !important;
  margin: 2rem 0;
  text-align: center;
}

@media (max-width: 991.98px) {
  .title {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }
}

:focus-visible {
  border: none !important;
  box-shadow: none;
  outline: none;
}

.form-content {
  position: absolute;
  bottom: 25px;
  left: 0%;
  width: 100%;
}

.form-content .form {
  background: linear-gradient(108deg, #189e9d 0%, #643e8b 62.88%);
  height: 66px;
  padding: 2px;
}

.btn-search {
  background-color: #643e8b !important;
  border: none;
  border-radius: 50% !important;
  height: 58px;
  padding: 0;
  position: absolute;
  right: 4px;
  top: 4px;
  transition: all 0.3s ease-in-out;
  width: 58px;
}

.btn-search:hover {
  background: linear-gradient(108deg, #643e8b 35%, #189e9d 100%);
}

.btn-search img {
  width: 100% !important;
}

.chat-content {
  height: calc(100vh - 320px);
  overflow-y: scroll;
  padding: 30px;
}

@media (max-width: 991.98px) {
  .chat-content {
    height: calc(100vh - 400px);
  }
}

.chat-content .chat-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.chat-content .chat-list li {
  display: flex;
  padding-top: 15px;
  width: 100%;
}

.chat-content .chat-list li.bot {
  justify-content: flex-start;
  padding-left: 45px;
  position: relative;
}

.chat-content .chat-list li.bot::before {
  content: "";
  background-image: url(./img/bot.svg);
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
}

.chat-content .chat-list li.user {
  justify-content: flex-end;
  padding-right: 45px;
  position: relative;
}

.chat-content .chat-list li.user::before {
  content: "";
  background-image: url(./img/user.svg);
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  right: 0;
}

.chat-content .chat-list li #message {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  color: #fff;
  font-size: 18px !important;
  margin: 0;
  padding: 10px 15px;
  position: relative;
  width: 50%;
  word-wrap: break-word;
  white-space: normal;
}

@media (max-width: 991.98px) {
  .chat-content .chat-list li #message {
    width: 100%;
  }
}

.chat-content .chat-list li.bot #message::before {
  content: "";
  width: 15px;
  aspect-ratio: 1;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  left: -15px;
  top: 10px;
}

.chat-content .chat-list li.user #message::before {
  content: "";
  width: 15px;
  aspect-ratio: 1;
  clip-path: polygon(0 0, 100% 0, 0 100%);
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  right: -15px;
  top: 10px;
}

#style-7::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

#style-7::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

#style-7::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #aaa;
}

/* CSS for animated 3 dots */
.dot-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.dot {
  width: 8px;
  height: 8px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #d3d3d3; /* Grayish white color */
  animation: bounce 0.8s infinite ease-in-out; /* Faster animation (0.8s duration) */
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
}
